'use client'

import { EMAIL, SUBMIT_BUTTON_ID } from 'back-in-stock/constants'
import { useFieldValidations } from 'country-configuration/services/client/useFieldValidations'
import { FormProvider, useForm } from 'form-components'
import { EmailInput } from 'form-components/components/EmailInput/EmailInput'
import { SubmitButton } from 'form-components/components/SubmitButton/SubmitButton'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useEffect } from 'react'
import { useSession } from 'session/src/hooks/useSession'

import { useBackInStock } from '../../../hooks/useBackInStock/useBackInStock'
import { GenericError } from './GenericError/GenericError'

import styles from './BackInStockForm.module.scss'

export function BackInStockForm(): React.ReactNode {
	const { pageType } = useMasterData()
	const { t } = useLabels()
	const { logged, email } = useSession()
	const { subscribeProduct, subscribeError } = useBackInStock()

	const defaultEmail = logged ? email : ''

	const formMethods = useForm({
		defaultValues: { email: defaultEmail },
		mode: 'onTouched',
	})

	const fieldValidations = useFieldValidations()

	const onSubmit = (values: { email: string }): Promise<void> => {
		return subscribeProduct(values.email)
	}

	const { handleSubmit, setError } = formMethods

	useEffect(() => {
		if (subscribeError) {
			setError('root', {})
		}
	}, [subscribeError])

	return (
		<FormProvider {...formMethods}>
			<form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
				<EmailInput
					name={EMAIL.name}
					id={EMAIL.id}
					rules={fieldValidations.email}
					data-testid={`${pageType}.${EMAIL.id}`}
				/>
				<GenericError />
				<SubmitButton
					id={SUBMIT_BUTTON_ID}
					data-testid={`${pageType}.${SUBMIT_BUTTON_ID}`}
				>
					{t('notifyMe.modal.button')}
				</SubmitButton>
			</form>
		</FormProvider>
	)
}
